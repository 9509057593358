import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import predictionsApolloClient from '@/lib/appsync/workflow';
import setpointApolloClient from '@/lib/appsync/setpoint';
import defermentApolloClient from '@/lib/appsync/deferment';
import oppPercentageApolloClient from '@/lib/appsync/oppPercentage';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import getOpsPagePredictionSummary from '@/graphql/predictions/queries/getOpsPagePredictionSummary.graphql';
import getSetpointCount from '@/graphql/predictions/queries/getSetpointCount.graphql';
import getDefermentAggLatest from '@/graphql/deferment/queries/getDefermentAggLatest.graphql';
import getPlannedWork from '@/graphql/workflow/queries/getPlannedWork.graphql';

import getDefermentAggLatestReport from '@/graphql/deferment/queries/getDefermentAggLatestReport.graphql';
import { DateTime } from 'luxon';
import workflowApolloClient from '@/lib/appsync/workflow';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'operationsModule',
  store,
})
class OperationsModule extends VuexModule {
  predictionTalliesDict = {};

  uplift = null

  optimized = null

  total = null

  units = "Loading..."

  defermentType = "-"

  defermentTotal = null

  plannedWorkDict = {}

  topDefermentArray = []

  tasqsPerRoleArray = []

  tasqsPerRouteArray = []

  tasqsPerPredictionTypeArray: any = []

  totalTasqsListArray: any[] = []

  @Mutation
  resetTotalTasqsListArray(): void {
	  this.totalTasqsListArray = [];
  }

  @Mutation
  resetTasqsPerPredictionTypeArray(): void {
	  this.tasqsPerPredictionTypeArray = [];
  }

  @Mutation
  addTotalTasqsListItem(totalTasqsListDict): void {
    this.totalTasqsListArray.push(totalTasqsListDict);
  }

  @Mutation
  setUnits(units): void {
    this.units = units;
  }

  @Mutation
  setDefermentType(defermentType): void {
    this.defermentType = defermentType;
  }

  @Mutation
  setDefermentTotal(defermentTotal): void {
    this.defermentTotal = defermentTotal;
  }

  @Mutation
  setTasqsPerPredictionTypeArray(tasqsPerPredictionTypeArray): void {
    this.tasqsPerPredictionTypeArray = tasqsPerPredictionTypeArray;
  }

  @Mutation
  setTasqsPerRouteArray(tasqsPerRouteArray): void {
    this.tasqsPerRouteArray = tasqsPerRouteArray;
  }

  @Mutation
  setTasqsPerRoleArray(setTasqsPerRoleArray): void {
    this.tasqsPerRoleArray = setTasqsPerRoleArray;
  }

  @Mutation
  setTopDefermentArray(topDefermentArray): void {
    this.topDefermentArray = topDefermentArray;
  }

  @Mutation
  setPredictionTalliesDict(predictionTalliesDict): void {
    this.predictionTalliesDict = predictionTalliesDict;
  }

  @Mutation
  setUplift(uplift): void {
    this.uplift = uplift;
  }

  @Mutation
  setOptimized(optimized): void {
    this.optimized = optimized;
  }

  @Mutation
  setTotal(total): void {
    this.total = total;
  }


  @Mutation
  setPlannedWorkDict(data): void {
    this.plannedWorkDict = data;
  }



  @Action
  async getPlannedWork({
  }): Promise<void> {

    const {
      data: {
        get_planned_work: results,
      },
    } = await workflowApolloClient.query({
      query: getPlannedWork,
      variables: {
		  input: {
			Operator: getConfigEnv('OPERATOR_LOWERCASED'),
		  }
        // from_date: fromDate,
        // to_date: to_date || DateTime.local().toFormat("y-MM-dd'T'HH:mm:ss"),
      },
    });
	// var parsed_results = 
	var plannedWorkDict = JSON.parse(results.Results)
	this.setPlannedWorkDict(plannedWorkDict)
    // const parsedData = JSON.parse(getDefermentAggLatestResult.dataset);
    // this.setDefermentTotal(getDefermentAggLatestResult.deferment_total);
	}







  @Action
  async getDefermentAggLatest({
    from_date,
    to_date,
    forReport = false,
    daysAgo = -1,
  }): Promise<void> {
    let fromDate = from_date;
    if (forReport) {
      fromDate = daysAgo != -1 ? DateTime.utc().minus({ days: daysAgo }).startOf('day').toFormat("y-MM-dd'T'HH:mm:ss")
        : DateTime.utc().startOf('day').toFormat("y-MM-dd'T'HH:mm:ss");
    }

    const {
      data: {
        get_deferment_agg_latest: getDefermentAggLatestResult,
      },
    } = await defermentApolloClient.query({
      query: getDefermentAggLatest,
      variables: {
		  operator: getConfigEnv('OPERATOR_LOWERCASED'),
        // from_date: fromDate,
        // to_date: to_date || DateTime.local().toFormat("y-MM-dd'T'HH:mm:ss"),
      },
    });
    const parsedData = JSON.parse(getDefermentAggLatestResult.dataset);
    this.setDefermentTotal(getDefermentAggLatestResult.deferment_total);
    

	this.setUnits(getDefermentAggLatestResult.units)
	this.setDefermentType(getDefermentAggLatestResult.deferment_type)
	const data = parsedData.nodeid?.map((i, idx) => ({
      wellName: parsedData?.nodeid[idx],
      value: parseInt(parsedData?.Value[idx]),
      defermentToday: parseInt(parsedData?.Today[idx]),
      defermentCumulative:  parseInt(parsedData?.Cumulative[idx]),
      area:  (parsedData?.Area[idx]),
      assignee: '',
      deferringDays: parsedData?.deferring_days[idx] <= 0 ? 0 : parsedData?.deferring_days[idx],
      id: i,
    })).sort((a, b) => b.value - a.value);
    this.setTopDefermentArray(data);
  }

  @Action
  async getSetpointCount(): Promise<void> {
    const {
      data: {
        get_setpoint_count: getSetpointResults,
      },
    } = await setpointApolloClient.query({
      query: getSetpointCount,
      variables: {
        operator: getConfigEnv('OPERATOR_LOWERCASED'),
      },
    });

    this.setUplift(JSON.parse(getSetpointResults.dataset).uplift);
    this.setOptimized(JSON.parse(getSetpointResults.dataset).optimized);
    this.setTotal(JSON.parse(getSetpointResults.dataset).total);
  }



  @Action
  async getPredictionTalliesOpt(): Promise<void> {
    this.resetTotalTasqsListArray();
    this.resetTasqsPerPredictionTypeArray();
    const {
      data: {
        get_ops_page_tallies: getOpsPagePredictionSummaryResults,
      },
    } = await predictionsApolloClient.query({
      query: getOpsPagePredictionSummary,
      variables: {
		  Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        Operator: getConfigEnv('OPERATOR_LOWERCASED'),
      },
    });

    const updatedRolesName = {
      FieldOperator: 'FieldOperator',
      Operator: 'Operators',
      Wireline: 'Wireline',
      Engineering: 'Engineering',
      Engineers: 'Engineering',
      Workover: 'Workover',
      Automation: 'Automation',
      Optimizers: 'Optimizers',
    };
    const roleDictParsed = JSON.parse(getOpsPagePredictionSummaryResults.RoleDict);
    const tasqsPerRoleArrayLocal: any[] = [];
    for (var key in roleDictParsed) {
      var roleValueArray = Object.values(roleDictParsed[key]);
      const add = (a, b) => a + b;
      const sum = roleValueArray.reduce(add);
      tasqsPerRoleArrayLocal.push({
        title: updatedRolesName[key] || key,
        value: sum,
      });
    }
    this.setTasqsPerRoleArray(tasqsPerRoleArrayLocal);

    const routeDictParsed = JSON.parse(getOpsPagePredictionSummaryResults.RouteDict);
    const tasqsPerRouteArrayLocal: any[] = [];
    for (var key in routeDictParsed) {
      tasqsPerRouteArrayLocal.push({
        title: routeDictParsed[key].RouteName,
        value: routeDictParsed[key].Items.length || 0,
      });
    }
    this.setTasqsPerRouteArray(tasqsPerRouteArrayLocal);

    for (let h = 0; h < getOpsPagePredictionSummaryResults.TotalTasqList.length; h++) {
      this.addTotalTasqsListItem(JSON.parse(getOpsPagePredictionSummaryResults.TotalTasqList[h]));
    }

    const tasqsPerPredictionTypeArrayLocal: any[] = [];
    const tasqsByPredictionTypeDict = {
      Workflow: { title: 'Workflow', value: 0 }, Predictions: { title: 'Prediction', value: 0 }, Anomaly: { title: 'Anomaly', value: 0 }, 'No Comms': { title: 'No Comms', value: 0 }, Prolonged: { title: 'Prolonged Anomaly', value: 0 }, 'Off-Target': { title: 'Off-Target', value: 0 }, 'Off-Target RT': { title: 'Real-Time', value: 0 },
    };
    for (let x = 0; x < this.totalTasqsListArray.length; x++) {
      const tasq = this.totalTasqsListArray[x];
      if (tasq.PredictionType == null || tasq.PredictionType == 'Setpoint') {
        continue;
      }
      let prediction_type = tasq.PredictionType;
      if (prediction_type == 'state change' || prediction_type == 'Prolonged Anomaly') {
        prediction_type = 'Anomaly';
      }

      if (!(prediction_type in tasqsByPredictionTypeDict)) {
        tasqsByPredictionTypeDict[prediction_type] = {
          title: prediction_type,
          value: 1,
          
        };
      } else {
        tasqsByPredictionTypeDict[prediction_type].value += 1;
      }
    }
    for (key in tasqsByPredictionTypeDict) {
      tasqsPerPredictionTypeArrayLocal.push(tasqsByPredictionTypeDict[key]);
    }

    this.setTasqsPerPredictionTypeArray(tasqsPerPredictionTypeArrayLocal);
  }
}

export default getModule(OperationsModule);
